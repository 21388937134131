<template>
  <div class="map-row">
    <!-- <div class="reminder">请先在地图中点选圈出片区位置</div> -->
    <!-- <div style="position:absolute;z-index:10;margin-left:20px;margin-top: 20px;"><el-button  size="mini" @click="goBacks()">返 回</el-button></div> -->
    <subMap ref="subMap" :path="path" @setLocation="setLocation" :layers="layers"/>
    
    <!-- <div class="dialogBoxedt" v-if="dialogTableVisible">
      <div class="gaine">
        <div class="fialogTitle">计划安排</div>
        <i class="el-icon-close modality" @click="close()"></i>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="formBox"
        >
          <el-form-item label="巡线区域" prop="networkName">
            <el-input
              v-model="form.networkName"
              maxlength="30"
              show-word-limit
              placeholder="请输入巡检区域名称"
              class="widthes"
            ></el-input>
          </el-form-item>
          <el-form-item label="巡线员" prop="executorPeopleId">
            <el-select
              v-model="form.executorPeopleId"
              placeholder="请选择"
              class="widthes"
            >
              <el-option
                v-for="item in excutorDownArray"
                :key="item.id"
                :label="item.username"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row style="text-align: center; margin-top: 53px">
            <el-button type="primary" @click="onSubmit" class="buttones"
              >确 认</el-button
            >
            <el-button class="buttones" @click="close()">重新绘制</el-button>
          </el-row>
        </el-form>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getUserList } from "@/RequestPort/maintenance/task";
import { networkDetail } from "@/apis/commonType";
import { ChangePlan } from "@/RequestPort/Inspection/inspePlan";
import subMap from "@/views/mapTool/subMapPlan";

export default {
  components: { subMap },

  name: "inspePlanAdd",

  data() {
    return {
      path: [],
      
      // 表单提交
      form: {
        executorPeopleId:''
      },
      layers:"'patrol_events','patrol_equipments','patrol_lines'",
      // 编辑计划弹窗
      dialogTableVisible: false,
      rules: {
        networkName: [{ required: true, message: "请选择", trigger: "change" }],
        executorPeopleId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      excutorDownArray: [],
    };
  },
  destroyed() {
    this.$eventBus.$off("transferAddForm");
  },
  mounted() {
    let that = this;
    
    // 提交获取addform坐标
    this.$eventBus.$on("transferAddForm", (data) => {
      this.form.location = this.setPolygon(data);
    });
    /**
     * 获取巡线员
     */
    getUserList({type:2}).then((e) => {
      that.excutorDownArray = e.data;
    });
    // 判断是否有id
    if (that.$route.query.id) {
      this.$eventBus.$emit("planDetails",true)
      that.dialogTableVisible = true;
      // 调取接口获取参数赋值
      networkDetail({ id: that.$route.query.id }).then((res) => {
        let data = res.data;
        if(!that.form.executorPeopleId){
        data.executorPeopleId = Number(that.$route.query.peopleId);
        }
        this.form = data
        that.form.networkId = that.form.id
        that.form.id = that.$route.query.planId
        // 判断是否是查询
        console.log(that.$route.query.inquire,"that.$route.query.inquire")
      if (that.$route.query.inquire) {
        // 是查询的话
        that.dialogTableVisible = false;
        this.$eventBus.$emit("planDetails",false)
        this.$eventBus.$emit("messagedetail",true)
        setTimeout(() => {
          this.$eventBus.$emit('showPolygon',that.form.location)
        },500)
      } else {
        this.$eventBus.$emit("vectorEdit", {
          data: { locations: that.form.location },
          type: "patrol_plan",
        });
      }
      });
      this.$eventBus.$emit("planadd",false)
    } else {
      this.$eventBus.$emit("planadd",true)
      this.$eventBus.$emit("messagedetail",false)
    }
  },
  methods: {
    goBacks(){
      window.history.go(-1)
    },
    setLocation(path) {
      let location = this.setPolygon(path);
      this.$eventBus.$emit("mapRegion", location);
      this.form.location = location;
      // let arr = point
      // arr.push(point[0])
      // let newArr = []
      // let strArr = []
      // arr.forEach(item => {
      //   newArr.push([item.lng, item.lat])
      //   strArr.push(`${item.lng} ${item.lat}`)
      // })
      // this.form.polygon = String(strArr)
      // let polygonObj = polygon([newArr])
      // let center = centerOfMass(polygonObj)
      // this.form.lon = center.geometry.coordinates[0]
      // this.form.lat = center.geometry.coordinates[1]
    },
    /**
     * 片区校验是否是正确的片区
     */
    setPolygon(path) {
      if (path && path.length >= 3) {
        var area = AMap.GeometryUtil.ringArea(path);
        if (area <= 0) {
          this.$nextTick(()=>{
          this.$refs.subMap.coloseForm()
        })
          this.$message.error("请绘制正确的片区");          
          return;
        }
      } else {
        this.$nextTick(()=>{
          this.$refs.subMap.coloseForm()
        })
        
        this.$message.error("请绘制正确的片区");
        return;
      }
      path.push(path[0]);
      let newPath = [];
      path.forEach((item, index) => {
        let nextItem = path[index + 1];
        if (nextItem) {
          if (nextItem.lng == item.lng && item.lat == nextItem.lat) {
          } else {
            newPath.push(item);
          }
        } else {
          newPath.push(item);
        }
      });

      return newPath;
    },
    setMapClick() {
      this.$refs.subMap.getLocation();
    },
    /**
     * 选择人员
     */
    // personnel(val){
    //   console.log(val)
    //   this.form.executorPeopleId = val
    //   // this.$set(this.form,"executorPeopleId",val)
    // },
    /**
     * 添加巡线计划
     */
    onSubmit() {
      this.$eventBus.$emit("gainAddForm");
      this.$refs.form.validate((valid) => {
        if (valid) {
          ChangePlan(this.form).then((res) => {
            this.dialogVisible = false;
            this.$message.success(res.msg);
            this.$router.push({ path: "/home/inspePlans" });
          });
        }
      });
    },
    /**
     * 关闭弹窗
     */
    close() {
      this.dialogTableVisible = false;
      this.form = {};
    this.$eventBus.$emit('closeEdit',{type:'patrol_networks_jihe'}) 
    },
  },
};
</script>

<style lang="less" scoped>
// 提示
// .reminder{
//   position: absolute;
//   top: 5%;
//   left: 50%;
//   width: 300px;
//   margin-left: -150px;
//   font-size: 18px;
//   z-index: 1;
//   color: #ff0000;
// }
.map-row {
  position: relative;
  // 弹窗盒子
  .dialogBoxedt {
    background: #ffffff;
    position: absolute;
    top: 30%;
    right: 2%;
    width: 420px;
    height: 363px;
    border-radius: 6px;
    // 内容盒子
    .gaine {
      position: relative;
      // 标题
      .fialogTitle {
        height: 60px;
        line-height: 60px;
        color: #0c235b;
        text-align: left;
        padding-left: 30px;
        font-size: 24px;
      }
      // 关闭按钮
      .modality {
        width: 19px;
        height: 19px;
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
      }
      // form盒子
      .formBox {
        margin: 53px 30px 10px;
        // 输入区域宽
        .widthes {
          width: 230px;
        }
        // 底部按钮宽
        .buttones {
          width: 160px;
        }
      }
    }
  }
}
</style>
